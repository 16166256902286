import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div>
              {/* <aside class="control-sidebar control-sidebar-dark"></aside>
                <footer class="main-footer">
                    <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>. </strong> All rights reserved. <div class="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.2.0
                    </div>
                </footer> */}
      </div>
    )
  }
}
